import type { ComponentType } from "react"

export function goBack(Component): ComponentType {
    return (props) => {
        const handleClick = () => {
            window.history.back()
        }
        return <Component {...props} onClick={handleClick} />
    }
}
